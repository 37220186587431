<template>
  <el-dialog
    class="searchPay-order-container"
    :title="popupsTitle"
    :visible="showState"
    width="600px"
    top="30vh"
    @close="hidePopups()"
  >
    <el-descriptions>
      <el-descriptions-item label="订单编号">{{ currentData.orderNo }}</el-descriptions-item>
    </el-descriptions>
    <div class="flex link-group">
      <div id="qrcode" ref="qrcode"></div>
      <!-- <span class="link">{{ currentData.payLink }}</span> -->
      <!-- <el-button type="primary" size="mini" @click="handleCopy()" v-if="!!currentData.payLink">复制</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import { getOrderPayLink } from '@/api/order'
import QRCode from 'qrcodejs2'
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  watch: {
    showState(val) {
      if (val) {
        getOrderPayLink({ data: this.currentData.orderId }).then((response) => {
          this.$nextTick(() => {
            const qrEl = this.$refs.qrcode
            // eslint-disable-next-line no-unused-vars, prefer-const
            let qrcode = new QRCode(qrEl)
            document.getElementById('qrcode').innerHTML = ''
            // eslint-disable-next-line no-unused-vars, prefer-const
            let qrcodes = new QRCode('qrcode', {
              width: 200, // 设置宽度，单位像素
              height: 200, // 设置高度，单位像素
              text: response.data.qrUrl, // 设置二维码内容或跳转地址
            })
          })
        })
      }
    },
  },
  methods: {
    async handleCopy(data) {
      const target = document.createElement('input') // 创建input节点
      target.value = this.currentData.payLink // 给input的value赋值

      document.body.appendChild(target) // 向页面插入input节点
      target.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$notify.success({ title: '成功', message: '复制成功', duration: 2000 })
      } catch {
        this.$notify.error({
          title: '成功',
          message: '当前浏览器不支持此功能，复制失败',
          duration: 2000,
        })
      }
      document.body.removeChild(target) // 删除插入的input节点
    },
    hidePopups() {
      this.$emit('hidePopups')
    },
  },
}
</script>

<style lang="scss" scoped>
.searchPay-order-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
  }
  .link-group {
    justify-content: space-between;
    align-items: center;
    span {
      color: #9a9d9e;
    }
  }
}
</style>
