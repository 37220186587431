<template>
  <el-dialog
    class="cancel-order-container"
    :title="popupsTitle"
    :visible="showState"
    width="600px"
    top="30vh"
    @close="hidePopups()"
  >
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="取消原因">
        <el-input v-model="form.cancelReason" type="textarea" :rows="6"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="hidePopups">取消</el-button>
      <el-button type="primary" :loading="submitLoadingStatus" @click="submitForm()"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { cancelOrder } from '@/api/order'
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      form: {
        cancelReason: undefined,
      },
    }
  },
  watch: {},
  methods: {
    submitForm() {
      this.submitLoadingStatus = true
      const formData = JSON.parse(JSON.stringify(this.form))
      formData.orderId = this.currentData.orderId
      cancelOrder({ data: formData })
        .then(() => {
          this.$notify.success({ title: '成功', message: '取消成功', duration: 2000 })
          this.$emit('refresh')
          this.hidePopups()
          this.submitLoadingStatus = false
        })
        .catch(() => {
          this.submitLoadingStatus = false
        })
    },
    hidePopups() {
      this.$emit('hidePopups')
      this.$refs.form.resetFields()
      Object.assign(this.$data.form, this.$options.data().form)
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel-order-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
  }
}
</style>
